import { ADD_ITEM, DISMISS_ITEM, CLEAR_ALL } from './constants';

const state = {
  notifications: [],
};

const getters = {
  notifications: ({ notifications }) => notifications,
};

const mutations = {
  [ADD_ITEM](state, item) {
    let stateItem = Object.assign({}, item);
    state.notifications.push(stateItem);
  },
  [DISMISS_ITEM](state, message) {
    state.notifications = state.notifications.filter(
      x => x.message !== message,
    );
  },
  [CLEAR_ALL](state) {
    state.notifications = [];
  },
};

const actions = {
  addItem({ state, commit }, item) {
    let notifExists = state.notifications.some(
      notif => notif.message.indexOf(item.message) !== -1,
    );
    // Check if a notification with this message already exists
    if (!notifExists) {
      // If it does not exist, push
      commit(ADD_ITEM, item);
      // Dismiss item after 5 sec
      setTimeout(() => commit(DISMISS_ITEM, item.message), 4000);
    }
  },
  dismissItem({ commit }, item) {
    commit(DISMISS_ITEM, item.message);
  },
  clear({ commit }) {
    commit(CLEAR_ALL);
  },
};

export const notifications = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
