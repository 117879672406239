import Vue from 'vue';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';

import Locale from './vue-i18n-locales.generated';
import App from './App.vue';
import router from './router';
import { store } from './store';

import { domain, clientId } from '../auth_config.json';
import { Auth0Plugin } from './auth';

Vue.use(VueI18n);
Vue.use(PortalVue);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.config.productionTip = false;

const lang = document.documentElement.lang.substr(0, 2);
// or however you determine your current app locale

const i18n = new VueI18n({
  locale: lang,
  messages: Locale,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
