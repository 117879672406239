export default {
  en: {
    shared: {
      submit: "Submit",
      cancel: "Cancel",
      delete: "Delete",
      new: "New worksheet",
      add: "Add",
      select: "Select",
      save: "Save",
      close: "Close",
      confirm: "Confirm",
      logout: "Log out"
    },
    milestones: {
      first_visit: "First visit",
      follow_up: "Follow up visit"
    },
    guidelines: {
      guidelines: "Guidelines",
      blueprint: "Blueprint",
      cta: "CTA",
      who: "WHO"
    },
    dialog: {
      save_selection: "Save selection",
      clear_worksheet: {
        header: "Clear worksheet?",
        body: "Your current worksheet will be deleted."
      },
      delete_column: {
        header: "Delete column?",
        body: "Your treatment option selections will be deleted."
      },
      add_column: "Add a column"
    },
    notifications: {
      remove_column: "Successfully deleted column",
      new_worksheet: "Successfully cleared worksheet"
    },
    options: {
      liver_function_test: "Liver Function Test",
      chronic_liver_diseases: "Chronic Liver Diseases",
      stbbi: "STBBI",
      fibrosis_assessment: "Fibrosis Assessment",
      ab_plus_test: "Ab+ test",
      point_of_care_test: "Point of Care Test",
      rna: "RNA",
      reflex_rna: "Reflex RNA",
      hcv_cag: "HCV cAg",
      genotype: "Genotype",
      ultrasound: "Ultrasound",
      resistance_testing: "Resistance Testing",
      svr_12: "SVR 12",
      svr_24: "SVR 24",
      prescribe_treatment: "Prescribe Treatment",
      start_treatment: "Start treatment",
      patient_history_risk_factors: "Patient History/Risk Factors",
      review_of_tests: "Review of Tests",
      patient_counceling_education: "Patient Counseling/Education",
      ddi_monitoring: "DDI Monitoring",
      check_in: "Check In",
      reimbursement: "Reimbursement",
      apri: "APRI",
      fib_4: "FIB-4",
      fibroscan: "FibroScan",
      other: "Other"
    },
    categories: {
      blood_work: {
        label: "Blood work",
        action_label: "{action} blood work"
      },
      testing: {
        label: "Testing",
        action_label: "{action} a test"
      },
      consultation: {
        label: "Consultation",
        action_label: "{action} a consultation"
      }
    }
  },
  fr: {
    shared: {
      submit: "Envoyer",
      cancel: "Annuler",
      delete: "Supprimer",
      new: "Nouvelle feuille de travail",
      add: "Ajouter",
      select: "Choisir",
      save: "Enregistrer",
      close: "Fermer",
      confirm: "Confirmer",
      logout: "Fermer la session"
    },
    milestones: {
      first_visit: "Première visite",
      follow_up: "Visite de suivi"
    },
    guidelines: {
      guidelines: "Lignes directrices",
      blueprint: "Modèle directeur",
      cta: "Appel à l’action",
      who: "OMS"
    },
    dialog: {
      save_selection: "Sauvegarder la sélection",
      clear_worksheet: {
        header: "Effacer la feuille de travail?",
        body: "Votre feuille de travail actuelle sera supprimée."
      },
      delete_column: {
        header: "Supprimer la colonne?",
        body: "Vos options de traitement sélectionnées seront supprimées."
      }
    },
    notifications: {
      remove_column: "Colonne supprimée avec succès",
      new_worksheet: "Feuille de travail effacée avec succès"
    },
    options: {
      liver_function_test: "Épreuves de la fonction hépatique",
      chronic_liver_diseases: "Maladies du foie chroniques",
      stbbi: "ITSS",
      fibrosis_assessment: "Évaluation de la fibrose",
      ab_plus_test: "Sérologie anti-VHC positive",
      point_of_care_test: "Dépistage au point de service",
      rna: "ARN",
      reflex_rna: "Dépistage réflexe de l’ARN",
      hcv_cag: "Antigène de la nucléocapside du VHC",
      genotype: "Génotype",
      ultrasound: "Échographie",
      resistance_testing: "Test de résistance",
      svr_12: "RVS12",
      svr_24: "RVS24",
      prescribe_treatment: "Prescrire un traitement",
      start_treatment: "Instauration du traitement",
      patient_history_risk_factors: "Antécédents/facteurs de risque du patient",
      review_of_tests: "Examen des tests",
      patient_counceling_education: "Conseils au patient/éducation du patient",
      ddi_monitoring: "Surveillance des IMM",
      check_in: "Vérification",
      reimbursement: "Remboursement",
      apri: "APRI",
      fib_4: "FIB-4",
      fibroscan: "FibroScan",
      other: "Autre"
    },
    categories: {
      blood_work: {
        label: "Analyses sanguines",
        action_label: "{action} les analyses sanguines"
      },
      testing: {
        label: "Tests",
        action_label: "{action} un test"
      },
      consultation: {
        label: "Consultation",
        action_label: "{action} une consultation"
      }
    }
  }
};
