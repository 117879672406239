const state = {
  columnDragging: false,
  columnChosen: false,
  cardDragging: false,
  peekedGuideline: null,
  reset: false
};

const mutations = {
  setColumnDragging(state, payload) {
    state.columnDragging = payload;
  },
  setColumnChosen(state, payload) {
    state.columnChosen = payload;
  },
  setCardDragging(state, payload) {
    state.cardDragging = payload;
  },
  setPeekedGuideline(state, payload) {
    state.peekedGuideline = payload;
  },
  toggleReset(state) {
    state.reset = !state.reset;
  }
};

export const roadmaps = {
  namespaced: true,
  state,
  mutations
};
